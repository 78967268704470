:root {
    --z-index-debug-tools: 100000;
    --z-index-onboarding-steps: 60000;
    --z-index-error-modal: 50000;
    --z-index-modal: 50000;
    --z-index-live-cursors: 45000;
    --z-index-drag-layer: 40000;
    --z-index-floating-panels: 35000;
    --z-index-drawing-editor: 31000;
    --z-index-header: 30000;
    --z-index-template-picker: calc(var(--z-index-header) - 1);
    --z-index-focus-layer: 29000;
    --z-index-toolbar: 20000;
    --z-index-inbox: 10000;
    --z-index-pointers: calc(var(--z-index-inbox) - 1);
    --z-index-selection-marquee: calc(var(--z-index-inbox) - 1);
    --z-index-inbox-header: 350;
    --z-index-canvas: 100;

    // Elements
    --z-index-element: 300;
    --z-index-element-hot-spot-drop-target: 120;
    --z-index-element-hot-spot-container: 110;

    // inside element
    --z-index-element-resize-handle: 2;
    --z-index-element-border: 10;
    --z-index-element-clipper: 30;
    --z-index-activity-indicator: 40;
    --z-index-element-tag: 45;
    --z-index-element-rich-media-preview-overlay: 50;

    // mobile
    --z-index-mobile-page: var(--z-index-canvas);
    --z-index-sheet: var(--z-index-modal);
    --z-index-mobile-toolbar: calc(var(--z-index-header) + 1);
    --z-index-sheet-with-toolbar: calc(var(--z-index-mobile-toolbar) - 1);
    --z-index-virtual-keyboard-debug: calc(var(--z-index-sheet-with-toolbar) + 1);
}
