@import '../../scss/settings';

.ModalActionsList {
    margin: 2.3rem -2rem;

    li {
        border-top: 1px solid var(--ui-divider-on-primary);

        &:last-of-type {
            border-bottom: 1px solid var(--ui-divider-on-primary);
        }

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            height: 4.9rem;

            padding: 0 2rem;

            color: var(--ui-foreground-primary);
            font-size: $font-size-ui-default;
            font-weight: $font-weight-semibold;
            text-align: left;
            text-decoration: none;

            &:hover {
                background: var(--ui-background-primary-hovered);
            }

            &:active {
                background: var(--color-neutral-500);
            }

            &:after {
                display: inline-block;
                width: 0.8rem;
                height: 1.4rem;
                background: url('../../scss/images/chevron-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                color: var(--ui-foreground-primary);
                content: '';
            }
        }
    }

    .app-size-mobile & {
        margin: 0;
    }
}
