@import '../../scss/settings';
@import '../../scss/mixins/outer-border-mixin';

.DragHandle {
    --line-handle-size-halved: calc(var(--line-handle-size) / -2);

    display: none;
    position: absolute;
    width: var(--line-handle-size);
    height: var(--line-handle-size);
    transform: translate(var(--line-handle-size-halved), var(--line-handle-size-halved));

    border-radius: 50%;

    box-shadow: var(--line-handle-shadow-elevation-1);
    z-index: 2;

    .handle {
        stroke-width: 0.5;

        width: var(--line-handle-size);
        height: var(--line-handle-size);

        overflow: visible;

        circle {
            stroke: $black-opacity-30;
            fill: var(--ws-line-edge-drag-handle-background);
        }
    }

    &.force-show,
    .selected:not(.marquee-remove-selected) &,
    .marquee-selected:not(.marquee-remove-selected) & {
        display: block;
    }

    // Need to raise specificity
    &.DragHandle.hit-area {
        width: 3rem;
        height: 3rem;
        transform: translate(-1.5rem, -1.5rem);

        border-radius: 0;
        box-shadow: none;
        cursor: move;
        opacity: 0;
        z-index: 3;
        pointer-events: all;
    }

    .editing & {
        opacity: 0;
    }

    .app-mode-mobile &,
    .platform-ipad & {
        &.DragHandle.hit-area {
            width: 4rem;
            height: 4rem;
            transform: translate(-2rem, -2rem);
        }
    }
}
