@import '../../../../../scss/settings';
@import '../../../../../scss/mixins/outer-border-mixin';

$color-display-tool-border-radius: 4px;

.ColorDisplayTool {
    position: relative;
}
.ColorDisplayTool,
.color-display-popup-content,
.color-display-content {
    .Tool .icon {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        border-radius: $color-display-tool-border-radius;
        background: var(--ui-toolbar-tool-background);
        color: var(--color-neutral-900);

        font-size: 11px;
        font-weight: $font-weight-semibold;
        cursor: pointer;

        overflow: hidden;

        .app-size-small & {
            width: 28px;
            height: 28px;

            font-size: 10px;
        }
    }

    .Tool.color-display-tool-option-off .icon {
        color: var(--ui-button-floating-secondary-text);
        font-weight: normal;
    }

    .color-display-contents {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
}

.color-display-toolbar-option {
    &.active .icon {
        background: var(--ui-toolbar-tool-popup-open-background);
    }
}

.FloatingPanel.color-display-popup {
    width: auto;

    .color-display-popup-content {
        display: flex;

        .Tool {
            padding: 0px 7px 0px 0px;

            &.color-display-tool-option-off {
                padding-right: 0;
                padding-left: 0.8rem;
            }

            &.active .icon {
                @include overlapping-border-full(2px, var(--ws-element-selection), $color-display-tool-border-radius);
            }
        }
    }
}
