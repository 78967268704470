:root {
    --sheet-transition-time: 0.5s;
    --sheet-transition-easing: cubic-bezier(0.32, 0.72, 0, 1.02);
}

@mixin sheet-transitions-mixin() {
    .Overlay {
        transition: opacity var(--sheet-transition-time) var(--sheet-transition-easing);
        opacity: 0;
    }

    .Sheet.sheet-transition {
        transition: all var(--sheet-transition-time) var(--sheet-transition-easing);
        transition-property: transform;
    }
}
