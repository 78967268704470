.MobileDocumentSheet.SheetContainer {
    overflow: visible;

    .Header {
        .Heading {
            width: 100%;

            .EditableTitle {
                font-family: inherit;
                line-height: 2.3rem;

                .editing {
                    background: var(--ui-modal-background-primary);
                }
            }
        }
        .CloseButton {
            color: var(--ui-canvas-foreground-secondary);
        }
    }

    .Content {
        --line-height-element: 2.3rem;

        padding-bottom: 0;
        font-size: var(--font-size-ui-large);

        #document-modal-content-container {
            position: relative;
            height: 100%;
            padding-bottom: 0;
        }

        #document-modal-content-scrollable-container {
            position: relative;
            overflow-y: scroll;
            height: 100%;
            padding-bottom: var(--safe-area-bottom);

            #document-modal-content {
                min-height: 100%;
                padding: 0 2.4rem 0 2.4rem;

                .DocumentModalTiptapEditorContainer {
                    .tiptap {
                        --space-above-editor: calc(var(--safe-area-top) + 53px);

                        min-height: calc(100vh - var(--space-above-editor) - var(--safe-area-bottom));
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
}
