@import '../../../scss/fonts';

.AppSplash {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;

    justify-content: center;

    width: 100dvw;
    height: 100dvh;

    // we always want a dark background on this screen
    background: var(--canvas-canvasdarkmode);
    font-size: 2dvh; // so that everything sized in ems will scale to device height

    text-align: center;
    grid-template-rows: 3.2fr 3fr 4.5fr 6fr;

    // Ensure the splash screen sits above the AppShell PageLoader header & toolbar
    z-index: max(calc(var(--z-index-mobile-toolbar) + 1), calc(var(--z-index-header) + 1));

    & > * {
        // the content should appear above the mascot
        // (but this element's own bg should be behind it)
        z-index: 1;
    }

    .mascot {
        position: absolute;
        width: 100%;
        height: 100%;

        background-image: url('./splashMascot.png');
        background-repeat: no-repeat;
        background-position-x: calc(50% - 4vh); // visually centered (the image is not symmetrical)
        background-position-y: bottom;
        background-size: auto 34em;
    }

    .logo-section {
        width: 100%;
    }

    .logo {
        height: 2.3em;
        margin-top: 3.4em;
        margin-bottom: 0.6em;
        svg {
            width: 2.3em;
            height: 2.3em;
        }
    }

    h1 {
        color: white;
        font-family: var(--font-brand);
        font-size: 1.4em;
        font-weight: normal;
    }

    h2 {
        color: white;
        font-family: var(--font-page-title);
        font-size: 1.9em;
        font-weight: bold;
        line-height: 1.1em;
    }

    .mascot-section {
        width: 30em;
        padding-top: 19.2em;

        background: url('./splashMascot.png') no-repeat;
        background-position: center;
        background-size: 3em;
    }

    .buttons {
        // signup button uses a darker text color than other secondary buttons
        --ui-foreground-secondary: var(--neutral-lightmodegrey-900);

        width: 19em;
        margin: auto;

        .dark-theme & {
            --ui-foreground-secondary: var(--neutral-darkmodegrey-050);
        }

        .StyledButton {
            padding: 1.3rem;
            border: none;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
