@import '../../scss/settings';
@import '../../scss/mixins/fade-move-transition-group-mixin';

.FloatingPanel {
    --arrow-color: var(--ui-tooltip-background-color);
    --arrow-stroke-color: var(--ui-tooltip-background-color);

    position: absolute;
    width: 27.6rem;
    padding: 1.4rem 3rem 1.8rem 3rem;

    border-radius: 0.6rem;

    background-color: var(--ui-tooltip-background-color);
    color: var(--ui-tooltip-color);

    line-height: 1.4;
    text-align: center;

    // FIXME Determine if there's a new tooltip design
    box-shadow: 0 1px 3px 0 $tooltip-shadow-color;

    z-index: var(--z-index-floating-panels);

    .primary-text {
        margin-top: -1px;
        font-size: $font-size-ui-default;
        font-weight: $font-weight-semibold;

        .app-size-small & {
            margin-top: 0;
        }
    }

    .ShortcutKeyGroup {
        --ui-shortcut-key-border-color: var(--ui-tooltip-shortcut-key-background-color);
        --ui-shortcut-key-background-color: var(--ui-tooltip-shortcut-key-background-color);
        --ui-shortcut-key-text-color: var(--ui-tooltip-shortcut-key-text-color);

        align-items: baseline;
        margin-left: 0.8rem;
        font-weight: normal;
    }

    .secondary-text {
        margin-top: 0.6rem;
        color: var(--ui-tooltip-secondary-color);
        font-size: $font-size-ui-small;
        font-weight: normal;

        ul {
            margin-left: 1rem;
            list-style: disc;
        }
    }

    .footer {
        display: flex;
        justify-content: center;

        margin-top: 1.6rem;
        margin-bottom: 0.8rem;

        font-size: $font-size-ui-small;

        .Button {
            width: auto;
        }
    }

    .tooltip-image {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.align-left {
        text-align: left;

        .footer {
            justify-content: flex-end;
        }
    }

    &.wide {
        width: 35rem;
    }

    &.small {
        width: auto;
        max-width: 28.5rem;
        padding: 0.5rem 1rem 0.6rem 1rem;
        border-radius: 0.3rem;

        pointer-events: none;

        .text {
            font-size: $font-size-ui-small;
            white-space: nowrap;
        }
    }

    &.light {
        --arrow-color: var(--ui-modal-background-primary);
        --arrow-stroke-color: var(--ui-modal-background-primary);

        background-color: var(--ui-modal-background-primary);
        color: var(--ui-modal-foreground-primary);
        box-shadow: var(--element-shadow-elevation-3);
    }

    &.fade {
        &.transition-top,
        &.top:not([class*="transition-"]) {
            @include fade-move-transition-group-mixin(0, 0, -8px, -8px, 0.1s);
        }

        &.transition-right,
        &.right:not([class*="transition-"]) {
            @include fade-move-transition-group-mixin(8px, 8px, 0, 0, 0.1s);
        }

        &.transition-bottom,
        &.bottom:not([class*="transition-"]) {
            @include fade-move-transition-group-mixin(0, 0, 8px, 8px, 0.1s);
        }

        &.transition-left,
        &.left:not([class*="transition-"]) {
            @include fade-move-transition-group-mixin(-8px, -8px, 0, 0, 0.1s);
        }
    }
}
