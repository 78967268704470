@import '../../../scss/settings';

.Tool {
    position: relative;
    padding: $tool-padding-regular;

    color: var(--ui-canvas-foreground-secondary);
    line-height: $font-size-toolbar;

    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    .contents {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;

        font-size: $font-size-toolbar;
    }

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    .tool-name {
        display: inline-block;
        max-width: 7.4rem;
        margin-top: 5px;
    }

    .BoardToolIcon {
        border-radius: 6px;
    }

    &.draggable {
        .contents {
            transition: transform 0.1s ease-in-out;
        }

        .icon {
            position: relative;
            filter: drop-shadow(0 1px 1px var(--ui-element-tool-draggable-drop-shadow-color));
            pointer-events: none;
        }

        // Don't change the filter property, only opacity.  This improves the performance of animations
        .draggable-shadow {
            position: absolute;

            transition: opacity 0.1s ease-in-out;
            text-align: center;

            opacity: 0;

            z-index: -1;
            filter: drop-shadow(-3px 2px 4px var(--ui-element-tool-draggable-drop-shadow-hover-color));
        }

        .tool-name {
            margin-top: 0.6rem;
        }
    }

    &.hoverable:hover {
        &.draggable {
            .contents {
                transform: translate3d(5px, 0, 0);
            }
        }

        .draggable-shadow {
            opacity: 1;
        }

        &.dragging {
            transform: translate3d(0, 0, 0);
        }
    }

    .toolbar-size-medium &,
    .toolbar-size-small & {
        .contents {
            font-size: $font-size-toolbar-small;
        }
    }

    .toolbar-size-small & {
        padding: $tool-padding-small;
    }
}
