@import '../../../../../scss/mixins/outer-border-mixin';

.MobileColorDisplaySheet {
    .Sheet {
        .Content .color-display-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: var(--modern-mobile-toolbar-height);

            .Tool {
                padding: 0 1rem;

                &.active .icon {
                    @include overlapping-border-full(2px, var(--ws-element-selection), 4px);
                }

                .icon {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
