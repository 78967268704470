@import '../../../../../../scss/settings';

.ToolbarPopup.TextStylePopup,
.ToolbarPopup.TextStyleRichTextPopup {
    --popup-arrow-color: var(--ui-block-style-active-background);

    width: 24.2rem;
    margin-left: 2px;
    padding: 0;

    .app-size-small & {
        margin-left: 2px;
    }

    .popup-content {
        border-radius: $ui-border-radius-default;
        overflow: hidden;
    }
}

.TextStylePopupContent {
    max-height: calc(var(--visual-viewport-height) - var(--top-position) - 2rem);
    padding: 0.7rem 0;

    overflow-y: auto;

    .block-styles {
        padding-bottom: 0.7rem;
    }

    .text-color-section {
        padding: 1.5rem 2rem 1.5rem 2rem;

        border-top: 1px solid var(--ui-modal-divider);

        text-align: left;

        &.color {
            padding-bottom: 1.8rem;
        }

        .text-color-section-title {
            color: var(--palette-charcoal-650);
            font-size: var(--font-size-ui-xsmall);
            font-weight: 600;
            letter-spacing: 0.01rem;
            user-select: none;
            -webkit-touch-callout: none;
        }

        .text-color-row {
            display: flex;
            justify-content: space-between;

            margin-top: 0.8rem;
            margin-right: 0.2rem;
            margin-left: 0.2rem;

            line-height: 0;

            cursor: pointer;
        }
    }

    &.color-only .color {
        border-top: 0;
    }
}
