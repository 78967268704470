// FIXME - Remove the "modern" prefix from the variable names when we remove the old UI
body {
    --safe-area-top: var(--capacitor-safe-area-top, env(safe-area-inset-top, 0px));
    --safe-area-bottom: var(--capacitor-safe-area-bottom, env(safe-area-inset-bottom, 0px));
    --safe-area-left: var(--capacitor-safe-area-left, env(safe-area-inset-left, 0px));
    --safe-area-right: var(--capacitor-safe-area-right, env(safe-area-inset-right, 0px));

    --mobile-toolbar-padding-bottom: var(--safe-area-bottom);

    --modern-mobile-primary-toolbar-height: 78px;
    --modern-mobile-secondary-toolbar-height: 56px;

    --modern-mobile-header-height: 50px;
    --modern-mobile-toolbar-height: var(--modern-mobile-primary-toolbar-height);

    --keyboard-animation-reveal-delay: 0.2s;
    --keyboard-animation-reveal-duration: 0.25s;

    --keyboard-animation-reveal-control-point-1-x: 0.3;
    --keyboard-animation-reveal-control-point-1-y: 0;
    --keyboard-animation-reveal-control-point-2-x: 0;
    --keyboard-animation-reveal-control-point-2-y: 1;

    --keyboard-animation-hide-delay: 0s;
    --keyboard-animation-hide-duration: 0.25s;

    --keyboard-animation-hide-control-point-1-x: 0.1;
    --keyboard-animation-hide-control-point-1-y: 0.5;
    --keyboard-animation-hide-control-point-2-x: 0;
    --keyboard-animation-hide-control-point-2-y: 1;
    --keyboard-height: 0px;
    --current-keyboard-height: 0px;

    // Since this is used often, sum the toolbar and safe area heights
    --workspace-safe-area-bottom: calc(var(--modern-mobile-toolbar-height) + var(--safe-area-bottom));

    &.browser-engine-ios-safari {
        --keyboard-animation-reveal-delay: 0.1s;
        --keyboard-animation-reveal-duration: 0.2s;
        --keyboard-animation-hide-delay: 0s;
        --keyboard-animation-hide-duration: 0.3s;
    }

    .keyboard-open {
        --current-keyboard-height: var(--keyboard-height);

        // We are using '--keyboard-bottom-toolbar-offset' and '--keyboard-toolbar-bottom-padding' to prevent
        // incorrect toolbar placement and padding caused when some android devices include the 'navigation bar'
        // in the keyboard height. These values are passed through from the device-side
        --safe-area-bottom: var(--keyboard-toolbar-bottom-offset, env(safe-area-inset-bottom, 0px));
        --mobile-toolbar-padding-bottom: var(--keyboard-toolbar-bottom-padding);
    }

    .debug-toolbar-enabled {
        --debug-toolbar-height: 26px;
    }

}
