@import 'colors';

:root {
    // Element spacing
    --element-paragraph-spacing-xlarge: 1.6rem;
    --element-paragraph-spacing-large: 1.2rem;
    --element-paragraph-spacing-default: 1rem;

    --element-default-width: 34rem;

    --element-padding-default: 2.4rem;
    --element-padding-small: 2rem;

    // This is padding between elements inside a list (besides columns), which should be 1GU apart.
    // 2px is added to the padding to cater for inset padding on elements.
    --element-in-list-vertical-padding: calc(1rem + 2px);
    --element-in-list-horizontal-padding: 0.8rem;

    // This is dependent on the size of the board icon, so use ems
    --board-border-radius: 1.6em;

    --ui-floating-panel-shadow-1: 0 1px 3px 0 rgba(50, 59, 74, 0.15);
    --ui-floating-panel-shadow-2:
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 6px 10px 0 rgba(0, 0, 0, 0.14);

    // Toasts on mobile have a different shadow so they don't look higher than the toolbar
    --ui-toast-mobile-shadow: 0 1px 8px 0 rgba(0,0,0,0.16);
    --ui-toast-mobile-contrast-border: 0.5px solid rgba(0,0,0,0.08);

    --modal-box-shadow: var(--ui-floating-panel-shadow-2);
    --modal-toolbar-box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    --element-shadow-elevation-1: 0 1px 2px 0 rgba(51, 61, 78, 0.25);
    --element-drop-shadow-elevation-1: drop-shadow(0 1px 1px rgba(81, 87, 95, 0.2))
        drop-shadow(0 1px 1.5px rgba(51, 61, 78, 0.1));
    --board-drop-shadow-elevation-1: drop-shadow(0 1px 1.5px rgba(51, 61, 78, 0.2));

    --element-shadow-elevation-1-contrast: var(--element-shadow-elevation-1), 0 0px 0px 1px var(--ws-canvas-background);

    --element-shadow-elevation-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --element-shadow-elevation-3: 0 11px 15px -7px rgba(51, 61, 78, 0.2), 0 9px 46px 8px rgba(51, 61, 78, 0.12),
        0 24px 38px 3px rgba(51, 61, 78, 0.14);
    --element-shadow-elevation-3-dark-mode: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 24px 38px 3px rgba(0, 0, 0, 0.14);

    --line-handle-size: 8px;
    --line-handle-shadow-elevation-1: 0 1px 2px 0 rgba(27, 37, 54, 0.25);

    --context-menu-shadow: 0 3px 9px 0 rgba(51, 61, 78, 0.25);

    --clipper-tool-border: 0 1px 1px rgba(51, 61, 78, 0.5);
    --clipper-tool-shadow: -5px 3px 4px rgba(110, 110, 113, 0.1);

    // Theme
    --ws-color-scheme: normal;

    // Interface
    --shadow-app-header: 0 1px 2px 0 rgba(51, 61, 78, 0.2);
    --shadow-modal: 0 3px 6px 0 #{$black-opacity-10}, inset 0 -1px 0 0 #{$grey-600}, inset -1px 0 0 0 #{$grey-500},
        inset 1px 0 0 0 #{$grey-500}, inset 0 1px 0 0 #{$grey-500};

    // Modal
    --modal-header-height: 5.6rem;
    --modal-transition-bg-duration: 0.2s;
    --modal-transition-delay-duration: 0.2s;
    --modal-transition-box-duration: 0.2s;
    --modal-transition-box-scale: 0.97;

    // Workspace app header
    --app-header-tools-section-height: 44px;
    --app-header-title-section-height: 5rem;
    --app-header-title-section-margin-top: -4px;
    --app-header-title-section-height-guest-view: 60px;

    --mobile-app-header-height: 52px;
    --mobile-detached-header-height: 4rem;

    // Header popup max-height
    --header-popup-max-height: min(75rem, calc(100vh - 20rem));

    --debug-ui-point-client-offset-color: white;
    --debug-ui-point-source-client-offset-color: black;
    --debug-ui-point-element-drag-group-positioner-color: purple;
    --debug-ui-point-element-drag-preview-positioner-color: green;

    --debug-ui-line-element-offset-color: red;
    --debug-ui-line-grab-offset-color: orange;
    --debug-ui-line-custom-drag-offset-color: blue;
}

.light-theme {
    // Theme
    --ws-color-scheme: light;

    // Element shadows
    --element-shadow-elevation-1: 0 1px 2px 0 rgba(51, 61, 78, 0.25);
    --element-shadow-elevation-1-contrast: var(--element-shadow-elevation-1), 0 0px 0px 1px var(--ws-canvas-background);
    --element-shadow-elevation-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --element-shadow-elevation-3: 0 11px 15px -7px rgba(51, 61, 78, 0.2), 0 9px 46px 8px rgba(51, 61, 78, 0.12),
        0 24px 38px 3px rgba(51, 61, 78, 0.14);

    // Image opacity
    --empty-element-list-placeholder-image-opacity: 0.5;
}

.dark-theme {
    // Theme
    --ws-color-scheme: dark;

    // Element shadows
    --element-shadow-elevation-1: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    --element-drop-shadow-elevation-1: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2))
        drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
    --board-drop-shadow-elevation-1: drop-shadow(0 1px 1.5px rgba(0, 0, 0, 0.25));

    --element-shadow-elevation-1-contrast: var(--element-shadow-elevation-1), 0 0px 0px 1px var(--ws-canvas-background);

    --element-shadow-elevation-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --element-shadow-elevation-3: var(--element-shadow-elevation-3-dark-mode);

    --context-menu-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.25);

    --clipper-tool-border: 0 1px 1px rgba(0, 0, 0, 0.5);
    --clipper-tool-shadow: -5px 3px 4px rgba(0, 0, 0, 0.1);

    --shadow-app-header: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    --shadow-modal: 0 3px 9px 0 rgba(0, 0, 0, 0.25);
}
