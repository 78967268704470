@import '../../scss/settings';

.MobilePageBody {
    display: flex;
    flex-direction: column;

    padding-right: var(--safe-area-right, 0px);
    padding-bottom: var(--workspace-safe-area-bottom);
    padding-left: var(--safe-area-left, 0px);

    overflow: auto;

    .CanvasScrollArea {
        // This prevents the page from moving when the toolbar height changes
        margin-bottom: calc(var(--modern-mobile-primary-toolbar-height) - var(--modern-mobile-toolbar-height));
    }
}
