@import '../../../../../scss/settings';
@import '../../../../../scss/mixins/outer-border-mixin';

.ToolbarPopup.LinePopup {
    width: auto;
    padding: 2.4rem;

    .content {
        line-height: 0;
    }

    .line-weight-popup-content {
        display: flex;
    }

    .Tool {
        position: relative;
        padding: 0px 7px 0px 0px;

        &::before {
            border-radius: $ui-border-radius-xsmall;
        }

        &:last-child {
            padding-right: 0px;
        }

        rect {
            fill: var(--ui-toolbar-tool-background);
        }

        &.active .icon {
            @include overlapping-border-full(2px, var(--ws-element-selection), 4px);
        }


        .icon {
            width: 32px;
            height: 32px;
        }

    }
}
