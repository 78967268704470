@import '../scss/settings';

.ListDropPreview {
    position: relative;

    .preview-line {
        position: absolute;
        top: calc((var(--element-in-list-vertical-padding) * -0.5) - 1px);
        left: 0;
        width: 100%;
        border-top: 2px solid var(--ws-element-can-drop);

        .app-size-medium & {
            transform: translateY(-0.5px);
        }
    }
}
