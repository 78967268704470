.SheetMenu {
    padding: 2rem 2rem;
    border-radius: 10px 10px 0 0;
    background-color: var(--ui-background-tertiary);
    
    li {
        border-bottom: 1px solid var(--ui-mobile-sheet-menu-border-color);

        &:last-child {
            border-bottom: none;
        }
    }

    .Button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        padding: 1rem;

        color: var(--ui-foreground-primary);
        font-size: var(--font-size-ui-large);

        .Icon {
            margin-right: 1rem;

            * {
                fill: var(--ui-foreground-primary);
            }
        }
    }

    .Sheet:has(&) {
        background: var(--ui-background-tertiary);
    }
}
